import { ApiClient, City } from "@syadem/ariane-js";
import { useEffect, useState } from "react";
import { useArianeApi } from "./useArianeApi";

export const useCitiesQuery = ({
  search,
  getByCode,
  splitZipCode,
  onArianeError,
}: {
  search?: string;
  getByCode?: string;
  splitZipCode?: boolean;
  onArianeError?: (error: unknown) => void;
}): [boolean, City[]] => {
  const [cities, setCities] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const arianeApi = useArianeApi();

  useEffect(() => {
    if (search && getByCode) {
      throw new Error("You cannot search and getByCode a City");
    }

    if (arianeApi) {
      (async () => {
        try {
          if (search) {
            const cities = await searchCity(search, arianeApi);
            setCities(splitZipCode ? splitCitiesZipCode(cities) : cities);
          } else if (getByCode) {
            const city = await getCityByCode(getByCode, arianeApi);
            setCities([city]);
          } else {
            setCities([]);
          }
          onArianeError && onArianeError(undefined);
        } catch (error) {
          onArianeError && onArianeError(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [arianeApi, getByCode, search, splitZipCode, onArianeError]);

  return [isLoading, cities];
};

async function searchCity(input: string, arianeApi: ApiClient): Promise<City[]> {
  switch (input) {
    case /^[0-9]/.test(input) ? input : "":
      return await arianeApi.cities.searchByZipCode(input.match(/^[0-9]*/)?.[0] ?? input);
    case /^LV-[0-9]/.test(input) ? input : "":
      return await arianeApi.cities.searchByZipCode(input.match(/^LV-[0-9]*/)?.[0] ?? input);
    case /^\w/.test(input) ? input : "":
      return await arianeApi.cities.search(input);
  }
  return [];
}

async function getCityByCode(code: string, arianeApi: ApiClient): Promise<City> {
  return arianeApi.cities.getByCode(code);
}

function splitCitiesZipCode(cities: City[]): City[] {
  const citiesSplited = cities.flatMap((city) => {
    if (city.zip_code.length <= 1) return city;

    return city.zip_code.map((code) => ({ ...city, zip_code: [code] } as City));
  });
  return citiesSplited;
}
