import { Alert, Box, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { HealthRecordForm, HealthRecordFormHandler } from "./HealthRecordForm";
import { useApis } from "../../providers/Dependencies";
import { useAppContext } from "../../hooks/useAppContext";

export function EditHealthRecord() {
  const { t } = useI18n();

  const apis = useApis();
  const { organizationId, teamId } = useAppContext();

  const { id } = useParams() as { id: string };
  const { healthRecord, refetch } = useHealthRecord(id);

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);

  const onSubmit: HealthRecordFormHandler = useCallback(
    (body, id) => {
      (async () => {
        try {
          // TODO: Wrap this inside a service
          if (organizationId && teamId) {
            await apis.team.healthRecordApi.updateHealthRecord(organizationId, teamId, id as string, body)
          } else {
            await apis.pro.healthRecordApi.updateHealthRecord(id as string, body)
          }

          setErrorDisplayed(false);
          setSuccessDisplayed(true);
          refetch();
        } catch (error) {
          setSuccessDisplayed(false);
          setErrorDisplayed(true);
        }
      })();
    },
    [apis, refetch, organizationId, teamId]
  );

  if (healthRecord === undefined) {
    return null;
  }

  return (
    <>
      <HealthRecordForm onSubmit={onSubmit} healthRecord={healthRecord} />
      <Box sx={{ maxWidth: "900px", mt: 2 }}>
        <Snackbar
          open={errorDisplayed}
          autoHideDuration={6000}
          onClose={() => setErrorDisplayed(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            position: "static",
            transform: "none !important",
          }}
        >
          <Alert severity="error">{t("common.alerts.alert_classic")}</Alert>
        </Snackbar>
        <Snackbar
          open={successDisplayed}
          autoHideDuration={6000}
          onClose={() => setSuccessDisplayed(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            position: "static",
            transform: "none !important",
          }}
        >
          <Alert severity="success">{t("health_records.success")}</Alert>
        </Snackbar>
      </Box>
    </>
  );
}
