import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AVAILABLE_LOCALES, LocaleString } from "../../translations";
import { useI18n } from "../hooks/useI18n";

import { dayjs } from "../../utils/dayjs";
import { theme } from "../layout/Theme";
import { FlagIcon } from "./FlagIcon";

export const LanguageSelector = ({ width = "auto" }: { width?: string }) => {
  const { setLocale, locale } = useI18n();

  const handleChange = (event: SelectChangeEvent) => {
    dayjs.locale(event.target.value);
    setLocale(event.target.value as LocaleString);
  };

  return (
    <Box sx={{ minWidth: 120, width: width, flexDirection: "row", display: "flex" }}>
      <FormControl variant="outlined" fullWidth size="small" sx={{ borderColor: theme.palette.neutral[200] }}>
        <Select
          id="language_selector"
          value={locale}
          onChange={handleChange}
          sx={{
            fontSize: 13,
            color: "inherit",
            "& .MuiSvgIcon-root": {
              color: theme.palette.neutral[600],
            },
            backgroundColor: "background.paper",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.neutral[300],
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.neutral[500],
            },
          }}
        >
          {AVAILABLE_LOCALES.map((locale) => (
            <MenuItem sx={{ color: "inherit" }} value={locale} key={locale}>
              <FlagIcon locale={locale} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
