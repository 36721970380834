
import {
  Alert,
  Box,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { PageLayout } from "../../layout/PageLayout";
import TeamAdminButton from "../../components/TeamAdminButton";
import { useApis } from "../../providers/Dependencies";
import { useSubscriptionOrganization } from "../../../store";
import { useAppContext } from "../../hooks/useAppContext";
import { useCountryConfig } from "../../hooks/useCountryConfig";

export function InviteMember() {
  const { t } = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didFail, setDidFail] = useState(false);
  const [didSucceed, setDidSucceed] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState<string>("");
  const apis = useApis();
  const { organizationId, teamId } = useAppContext();
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { subscription } = useCountryConfig();
  const subscriptionOrganization = useSubscriptionOrganization(organizationId ?? undefined) ?? undefined;
  const [availableSeatsCount, setAvailableSeatsCount] = useState<number | undefined>(undefined);

  const isValidEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const isOnDefaultSubscriptionPlan =
    subscriptionOrganization && subscription &&
    subscriptionOrganization.subscription.product.id == subscription.defaultProductId

  const hasNoSeatsLeft = isOnDefaultSubscriptionPlan && !!availableSeatsCount && availableSeatsCount < 1

  const handleSubmit = async () => {
    if (teamId === null || organizationId == null) {
      return;
    }
    if(isValidEmail(email) === false) {
      setEmailError(true);
      return;
    }
    try {
      setDidSucceed(false);
      setIsSubmitting(true);
      // TODO: Wrap this inside a service
      await apis.team.teamApi.sendInvitationEmail(organizationId, teamId, {email: email});
      setIsSubmitting(false);
      setDidSucceed(true);
      setEmail("");
    } catch (e) {
      setDidFail(true);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (subscription) {
      subscription.queries.subscriptionOrganizationsQuery.call();
    }
  }, [subscription]);

  useEffect(() => {
    if (subscriptionOrganization) {
      (async () => {
        // TODO: Wrap this inside a query
        const organizationMembers = (await apis.team.organizationApi.indexMembers(subscriptionOrganization.id)).professionalAccounts;
        setAvailableSeatsCount(subscriptionOrganization.subscription.quantity * 5 - organizationMembers.length);
      })();
    }
  }, [apis, subscriptionOrganization]);

  return (
    <PageLayout title={t("team.invite")} actions={<TeamAdminButton />}>
      {!!availableSeatsCount && (
        <Box
          sx={{
            textAlign: "center",
            ".icon": {
              height: "40px !important",
              color: theme.palette.primary[500],
            },
            padding: { xs: 4 },
            border: `solid 1px ${theme.palette.neutral[300]}`,
            borderRadius: 1,
            backgroundColor: "background.paper",
            mt: 1,
          }}
        >
          {isOnDefaultSubscriptionPlan === true && (
            hasNoSeatsLeft === true ? (
              <Alert severity="error">{t("team.sendInvitation.noSeatsLeft")}</Alert>
            ): (
              <Alert severity="info">{t("team.sendInvitation.seatsAmountLeft", { amount: availableSeatsCount })}</Alert>
            )
          )}

          <Typography my={5}>{t("team.sendInvitation.enterEmail")}</Typography>

          <TextField
            placeholder="membre@equipe.fr"
            value={email}
            onChange={
              (e) => {
                setEmailError(false);
                setEmail(e.target.value)
              }
            }
            type={"email"}
            variant="outlined"
            error={emailError}
            required
            size="small"
            disabled={hasNoSeatsLeft === true}
            sx={{ marginBottom: 5, width: breakpointSmDown ? "60%" : "50%" }}
          />
          <Typography sx={{ marginBottom: 5 }}>{t("team.sendInvitation.description")}</Typography>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            color="primary"
            onClick={handleSubmit}
            disabled={hasNoSeatsLeft === true}
          >
            {t("team.sendInvitation.cta")}
          </LoadingButton>

          {didFail && (
            <Alert data-testid="alert" severity="error" sx={{ marginY: "3em" }}>
              {t("common.alerts.alert_notification")}
            </Alert>
          )}
          {didSucceed && (
            <Alert severity="info">{t("team.sendInvitation.success")}</Alert>
          )}
        </Box>
      )}
    </PageLayout>
  );
}
